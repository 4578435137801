










































































































import { defineComponent, ref, computed } from "@vue/composition-api";

import formatNumber from "@/components/Hooks/formatNumber";
import useProductTimer from "@/components/Product/Hooks/useProductTimer";

import { Product } from "@/components/Product/Types";

export default defineComponent({
  props: {
    product: {
      type: Object as () => Product,
      required: true,
    },
  },
  setup(props) {
    const isThumbnailBroken = ref(false);

    const formattedPrice = formatNumber(props.product.price);
    const formattedMarketPrice = formatNumber(props.product.marketPrice);
    const discountRate = Math.round(
      ((props.product.marketPrice - props.product.price) /
        props.product.marketPrice) *
        100
    );

    const { formattedDuration: startDateTimer, duration: startDateDuration } =
      useProductTimer(props.product.startAt);

    const { formattedDuration: endDateTimer, duration: endDateDuration } =
      useProductTimer(props.product.endAt);

    const isSoldOut = computed(() => {
      return (
        props.product.state === "SOLDOUT" ||
        props.product.state === "INACTIVE" ||
        props.product.stock === 0 ||
        (props.product.endAt != null && endDateDuration.value <= 0)
      );
    });

    return {
      isThumbnailBroken,
      formattedPrice,
      formattedMarketPrice,
      discountRate,
      isSoldOut,
      startDateTimer,
      startDateDuration,
      endDateTimer,
      endDateDuration,
    };
  },
});
