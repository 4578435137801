














import {
  defineComponent,
  onBeforeUnmount,
  ref,
  watch,
} from "@vue/composition-api";
import { useElementVisibility } from "@vueuse/core";

export default defineComponent({
  emits: ["close"],
  setup() {
    const modalEl = ref();

    const isModalVisible = useElementVisibility(modalEl);

    watch(
      () => isModalVisible.value,
      (newValue) => {
        if (newValue) {
          document.body.classList.add("modal-open");
        } else {
          document.body.classList.remove("modal-open");
        }
      }
    );

    onBeforeUnmount(() => {
      document.body.classList.remove("modal-open");
    });

    return {
      modalEl,
    };
  },
});
