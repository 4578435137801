

















// types
import { Shop } from "@/components/Shop/Types";

import { defineComponent, computed } from "@vue/composition-api";

import checkDarkTheme from "@/components/Shop/Hooks/checkDarkTheme";

export default defineComponent({
  props: { shopInfo: { type: Object as () => Shop, required: true } },
  setup(props, context) {
    const addAtHomeHandler = () => {
      alert("준비중입니다.");
    };

    const deliveryPage = computed(
      () => `/id/${props.shopInfo.urlPath}/delivery`
    );
    const cartPage = computed(() => `/id/${props.shopInfo.urlPath}/cart`);

    const store = context.root.$store;
    const cartLength = computed(() => {
      if (!store.state.cartItems) return 0;
      const cartItems = store.state.cartItems[props.shopInfo.idx];
      return cartItems ? cartItems.length : 0;
    });

    const isDarkTheme = computed(() => {
      if (!props.shopInfo) return false;

      const isDark = checkDarkTheme(props.shopInfo.color);

      return isDark;
    });

    return {
      addAtHomeHandler,
      deliveryPage,
      cartPage,
      cartLength,
      isDarkTheme,
    };
  },
});
