



































import { defineComponent } from "@vue/composition-api";

export default defineComponent({
  props: {
    width: {
      type: [String, Number],
      default: 44,
    },
    height: {
      type: [String, Number],
      default: 44,
    },
    storeColor: {
      type: String,
      default: "#868E96",
    },
    alertColor: {
      type: String,
      default: "#0078FF",
    },
  },
});
