var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"product-preview"},[_c('router-link',{staticClass:"product-preview__link",attrs:{"to":{ path: ("product/" + (_vm.product.productNo)) },"append":""}},[_c('div',{staticClass:"product-preview__thumbnail-area"},[(_vm.product.thumbnailImg || !_vm.isThumbnailBroken)?_c('img',{staticClass:"product-preview__thumbnail-img",attrs:{"src":_vm.product.thumbnailImg,"alt":""},on:{"error":function($event){_vm.isThumbnailBroken = true}}}):_vm._e(),_c('div',{staticClass:"product-preview__badge-container"},[(_vm.startDateDuration > 0)?_c('div',{staticClass:"product-preview__badge"},[_vm._v(" "+_vm._s(_vm.$t("SCHEDULE_TO_OPEN"))+" ")]):(_vm.product.sellType === 'RECRUITMENT')?_c('div',{staticClass:"product-preview__badge product-preview__badge--special"},[_vm._v(" "+_vm._s(_vm.$t("SPECIAL_GROUP_PURCHASE"))+" ")]):_vm._e()]),(_vm.isSoldOut)?_c('div',{staticClass:"product-preview__sold-out-message"},[_vm._v(" 품절 ")]):(_vm.product.startAt != null && _vm.startDateDuration > 0)?_c('div',{staticClass:"product-preview__timer"},[_c('div',[_vm._v(_vm._s(_vm.startDateTimer))])]):(
          _vm.product.sellType === 'RECRUITMENT' &&
          _vm.product.endAt != null &&
          _vm.endDateDuration > 0
        )?_c('div',{staticClass:"product-preview__timer"},[_vm._v(" "+_vm._s(_vm.endDateTimer)+" ")]):_vm._e()]),_c('div',{staticClass:"product-preview__product-name-area"},[_c('div',{staticClass:"product-preview__brand-name",class:{
          'product-preview__brand-name--sold-out': _vm.isSoldOut,
        }},[_vm._v(" "+_vm._s(_vm.product.brandName)+" ")]),_c('div',{staticClass:"product-preview__product-name",class:{
          'product-preview__product-name--sold-out': _vm.isSoldOut,
        }},[(_vm.product.isAbroad)?_c('span',{staticClass:"product-preview__abroad-badge"},[_vm._v(_vm._s("해외"))]):_vm._e(),_vm._v(" "+_vm._s(_vm.product.productName)+" ")])]),_c('div',{staticClass:"product-preview__discount-area"},[_c('span',{staticClass:"product-preview__market-price",class:{
          'product-preview__market-price--sold-out': _vm.isSoldOut,
        }},[_c('span',{staticClass:"product-preview__market-price-num"},[_vm._v(_vm._s(_vm.formattedMarketPrice))]),_vm._v("원")]),_c('span',{staticClass:"product-preview__discount-rate",class:{
          'product-preview__discount-rate--sold-out': _vm.isSoldOut,
        }},[_c('span',{staticClass:"product-preview__discount-rate-num"},[_vm._v(_vm._s(_vm.discountRate))]),_vm._v("% ")])]),_c('div',{staticClass:"product-preview__price-area",class:{
        'product-preview__price-area--sold-out': _vm.isSoldOut,
      }},[_c('span',{staticClass:"product-preview__price-num"},[_vm._v(_vm._s(_vm.formattedPrice))]),_vm._v("원 ")])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }