




























































































































































import {
  defineComponent,
  ref,
  onMounted,
  onBeforeUnmount,
  watch,
  unref,
  computed,
  onUnmounted,
} from "@vue/composition-api";
import { useScriptTag, useMutationObserver } from "@vueuse/core";

import { Shop } from "@/components/Shop/Types";

import useProductList from "@/components/Product/Hooks/useProductList";

import ShopHeader from "@/components/Layout/ShopHeader.vue";
import ShopInfoMain from "@/components/Shop/ShopInfoMain.vue";
import ProductPreviewLink from "@/components/Product/ProductPreviewLink.vue";
import LoadingSpinnerBox from "@/components/Common/LoadingSpinnerBox.vue";
import BaseFooter from "@/components/Layout/BaseFooter.vue";
import ChannelTalk from "@/components/ChannelTalk/ChannelTalk.vue";
import ScrollTopButton from "@/components/Common/ScrollTopButton.vue";
import BaseHeader from "@/components/Layout/BaseHeader.vue";
import IntersectionTrigger from "@/components/Common/IntersectionTrigger.vue";
import EmptyStoreIcon from "@/assets/ElementsImage/EmptyStoreIcon.vue";
import SearchIcon from "@/assets/ElementsImage/SearchIcon.vue";
import TopFixedModal from "@/components/Common/TopFixedModal.vue";
import CloseIcon from "@/assets/ElementsImage/CloseIcon.vue";
import MainTextInputIcon from "@/components/Common/MainTextInputIcon.vue";
import DeleteIcon from "@/assets/ElementsImage/DeleteIcon.vue";

export default defineComponent({
  components: {
    ShopHeader,
    ShopInfoMain,
    ProductPreviewLink,
    LoadingSpinnerBox,
    BaseFooter,
    ChannelTalk,
    ScrollTopButton,
    BaseHeader,
    IntersectionTrigger,
    EmptyStoreIcon,
    SearchIcon,
    TopFixedModal,
    CloseIcon,
    MainTextInputIcon,
    DeleteIcon,
  },
  props: {
    shopInfo: {
      type: Object as () => Shop,
      required: true,
    },
  },
  setup(props) {
    const {
      products,
      loading,
      hasNextPage,
      errorCode,
      nameOrBrandKeyword,
      resetPage,
      setProductList,
      appendProductList,
    } = useProductList(props.shopInfo.urlPath, { immediate: true });

    const savedNameOrBrandKeyword = ref(unref(nameOrBrandKeyword));

    const onIntersect = async () => {
      if (loading.value || !hasNextPage.value) {
        return;
      }

      await appendProductList();
    };

    watch(
      () => errorCode.value,
      (newValue) => {
        if (newValue != null) {
          alert("상품리스트 불러오기에 실패했습니다. 다시 시도해주세요.");
        }
      }
    );

    const myshopProductsScrollEl = ref();
    const isScrollTopBtnVisible = ref(false);
    const isHeaderExtended = ref(true);

    const scrollHandler = async () => {
      if (myshopProductsScrollEl.value.scrollTop >= 400) {
        isScrollTopBtnVisible.value = true;
      } else {
        isScrollTopBtnVisible.value = false;
      }

      if (myshopProductsScrollEl.value.scrollTop > 0) {
        isHeaderExtended.value = false;
      } else {
        isHeaderExtended.value = true;
      }
    };

    const scrollTopHandler = () => {
      myshopProductsScrollEl.value.scrollTo({
        top: 0,
      });
      document.documentElement.scrollTo({ top: 0 });
    };

    const globalScrollHandler = async () => {
      const documentEl = document.documentElement;

      if (documentEl.scrollTop >= 400) {
        isScrollTopBtnVisible.value = true;
      } else {
        isScrollTopBtnVisible.value = false;
      }

      if (documentEl.scrollTop > 0) {
        isHeaderExtended.value = false;
      } else {
        isHeaderExtended.value = true;
      }
    };

    onMounted(() => {
      document.addEventListener("scroll", globalScrollHandler);
    });

    onBeforeUnmount(() => {
      document.removeEventListener("scroll", globalScrollHandler);
    });

    const isSearchModalOpened = ref(false);
    const isSearchFormVisible = ref(false);

    const onSubmitProductSearch = async () => {
      if (savedNameOrBrandKeyword.value !== nameOrBrandKeyword.value) {
        resetPage();
        products.value = [];
        await setProductList();
        savedNameOrBrandKeyword.value = unref(nameOrBrandKeyword);
      }

      isSearchModalOpened.value = false;

      if (nameOrBrandKeyword.value !== "") {
        isSearchFormVisible.value = true;
      } else {
        isSearchFormVisible.value = false;
      }
    };

    const onClearProductSearch = async () => {
      nameOrBrandKeyword.value = "";
      resetPage();
      products.value = [];
      isSearchFormVisible.value = false;
      await setProductList();
    };

    const isAdsenseVisible = computed(() => {
      const disallowedStoreIds: string[] = [];
      return !disallowedStoreIds.includes(props.shopInfo.urlPath);
    });

    const adsenseAddLoad = () => {
      const inlineScript = document.createElement("script");
      inlineScript.type = "text/javascript";
      inlineScript.text = "(adsbygoogle = window.adsbygoogle || []).push({});";
      document.body.appendChild(inlineScript);
    };

    const { load, unload } = useScriptTag(
      "https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-7501014469009823",
      () => {
        console.log("google adsense start");
      },
      {
        manual: true,
        crossOrigin: "anonymous",
        async: true,
      }
    );

    onMounted(async () => {
      if (isAdsenseVisible.value) {
        adsenseAddLoad();
      }
    });

    const myshopProductsContainerRef = ref<HTMLDivElement | null>(null);
    useMutationObserver(
      myshopProductsContainerRef,
      (mutations) => {
        if (mutations[0]) {
          if (myshopProductsContainerRef.value) {
            myshopProductsContainerRef.value.style.height = "";
          }
        }
      },
      { attributes: true }
    );

    const adsenseContainerRef = ref<HTMLDivElement | null>(null);
    useMutationObserver(
      adsenseContainerRef,
      (mutations) => {
        if (mutations[0]) {
          if (adsenseContainerRef.value) {
            adsenseContainerRef.value.style.height = "";
          }
        }
      },
      { attributes: true }
    );

    return {
      products,
      loading,
      myshopProductsScrollEl,
      isScrollTopBtnVisible,
      isHeaderExtended,
      scrollHandler,
      scrollTopHandler,
      isSearchModalOpened,
      appendProductList,
      onIntersect,
      nameOrBrandKeyword,
      onSubmitProductSearch,
      isSearchFormVisible,
      savedNameOrBrandKeyword,
      onClearProductSearch,
      isAdsenseVisible,
      myshopProductsContainerRef,
      adsenseContainerRef,
    };
  },
});
