import { ref, unref } from "@vue/composition-api";
import axios from "axios";
import { tryOnMounted } from "@vueuse/core";

import type { MaybeRef } from "@vueuse/core";
import type { Product } from "@/components/Product/Types";

import { getList } from "@/api/product";

export default (
  storeUrl: string,
  config: { immediate?: boolean; initialKeyword?: MaybeRef<string> } = {}
) => {
  const { immediate, initialKeyword } = config;

  const products = ref<Product[]>([]);
  const page = ref(1);
  const loading = ref(false);
  const hasNextPage = ref(true);
  const nameOrBrandKeyword = ref(unref(initialKeyword) ?? "");
  const errorCode = ref<number | null>();

  const resetPage = () => {
    page.value = 1;
  };

  const setProductList = async () => {
    try {
      loading.value = true;
      const { next: nextPageUrl, results } = await getList(storeUrl, {
        page: page.value,
        productNameOrBrand: nameOrBrandKeyword.value,
      });

      products.value = results;
      errorCode.value = null;
      hasNextPage.value = !!nextPageUrl;

      if (hasNextPage.value) {
        page.value += 1;
      }
    } catch (err) {
      // console.log(err);

      if (axios.isAxiosError(err)) {
        errorCode.value = err.response?.data.code ?? 400;
      } else {
        errorCode.value = 400;
      }
    } finally {
      loading.value = false;
    }
  };

  const appendProductList = async () => {
    try {
      loading.value = true;
      const { next: nextPageUrl, results } = await getList(storeUrl, {
        page: page.value,
        productNameOrBrand: nameOrBrandKeyword.value,
      });

      products.value = [...products.value, ...results];
      errorCode.value = null;
      hasNextPage.value = !!nextPageUrl;

      if (hasNextPage.value) {
        page.value += 1;
      }
    } catch (err) {
      // console.log(err);

      if (axios.isAxiosError(err)) {
        errorCode.value = err.response?.data.code ?? 400;
      } else {
        errorCode.value = 400;
      }
    } finally {
      loading.value = false;
    }
  };

  if (immediate) {
    tryOnMounted(async () => {
      await setProductList();
    });
  }

  return {
    products,
    page,
    loading,
    hasNextPage,
    nameOrBrandKeyword,
    errorCode,
    resetPage,
    setProductList,
    appendProductList,
  };
};
