






import {
  defineComponent,
  ref,
  onMounted,
  onUnmounted,
} from "@vue/composition-api";

interface IntersectionObserverInit {
  root: Element | null;
  rootMargin: string;
  threshold: number | number[];
}

export default defineComponent({
  props: {
    options: {
      type: Object as () => IntersectionObserverInit,
    },
  },
  emits: ["intersect"],
  setup(props, context) {
    const triggerEl = ref();

    let observer: IntersectionObserver;

    const onIntersect = (entry: IntersectionObserverEntry) => {
      if (entry.isIntersecting) {
        context.emit("intersect", entry);
      }
    };

    onMounted(() => {
      observer = new IntersectionObserver((entries) => {
        onIntersect(entries[0]);
      }, props.options);

      observer.observe(triggerEl.value);
    });

    onUnmounted(() => {
      observer.disconnect();
    });

    return {
      triggerEl,
    };
  },
});
